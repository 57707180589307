<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
/**
  README

  Do NOT add any additional styles here.

  To add new classes use /src/styles/app.scss file.

  To override Vuetify classes/styles use /src/styles/overrides.scss file.
*/
@import "./styles/app.scss";
</style>
